import L from 'leaflet'
import { Popup, Marker } from 'react-leaflet'

function RestaurantMarker(props) {
    return <Marker 
                key={"m_" + props.index} 
                position={[props.item.latitude, props.item.longitude]} 
                icon={
                    L.icon({
                        className: "restaurant-icon",
                        iconSize: [20, 20],
                        iconAnchor: [0, 0],
                        popupAnchor: [15, 0],
                        iconUrl: props.item.icon
                    })
                } 
            >
        <Popup>
            <strong>{props.item.name}</strong>
            <p>{props.item.location}</p>
            <p>Rating: {(props.item.rating ? props.item.rating : 'N/A')}</p>
            <p>Distance: {props.item.distance} miles</p>
        </Popup>
    </Marker>
}

export default RestaurantMarker