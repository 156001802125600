import React from 'react'
import { MapContainer, TileLayer, Popup, Marker } from 'react-leaflet'
import "leaflet/dist/leaflet.css"
import { myPosIcon } from '../icons/Icons'
import SetView from './SetView'
import Controls from './Controls'
import RestaurantMarker from '../icons/RestaurantMarker'
import Loader from '../components/Loader'


class PlacesOfInterest extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
           lat: 0,
           lng: 0,
           position: [0,0],
           loading: true,
           type: 'pizza',
           radius: 500,
           zoom: 12,
           data: []
        }

        this.handleButtonClick = this.handleButtonClick.bind(this)
        this.setFoodType = this.setFoodType.bind(this)
        this.setRadius = this.setRadius.bind(this)
        this.getGeoLocation = this.getGeoLocation.bind(this)
    }

    componentDidMount() {
        this.getGeoLocation()
    }

    getGeoLocation() {
        this.setState({
            loading: true,
            data: []
        })
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
                this.setState({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                    zoom: 12,
                    position: [position.coords.latitude, position.coords.longitude],
                    loading: false
                })
            }
          )
        } else {
            console.log("there was a problem")
        }
    }

    setFoodType(event) {
        this.setState({
            type: event.target.value
        })
    }

    setRadius(event) {
        this.setState({
            radius: event.target.value
        })
    }

    handleButtonClick() {
        this.setState({
            position: [this.state.lat, this.state.lng],
            zoom: 12,
            loading: true
        })
    
        let parameters = {
            "latitude": this.state.lat, 
            "longitude": this.state.lng,
            "radius": this.state.radius,
            "type": "restaurant",
            "keyword": this.state.type
        }

        fetch('http://0.0.0.0:80/api/test', {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                'Access-Control-Allow-Methods': '*',
                'Access-Control-Allow-Headers': '*'
            },
            body: JSON.stringify(parameters)
        })
        .then(response => response.json())
        .then(data => {
            if (data.payload.length > 0) {
                this.setState({
                    data: data.payload,
                    loading: false
                })
            }
            else {
                this.setState({
                    loading: false
                })
                alert('Your search returned no results, try again')
            }
        })
        .catch(error => {
            // alert('There was a problem, speak to the adminisrator or try again later')
            let data = [
                {
                    "name": "Brook Street Cafe & Bistro (David loves this place)",
                    "location": "71 Brook St, Chester CH1 3DZ",
                    "latitude": 53.19528,
                    "longitude": -2.88610,
                    "rating": 5.0,
                    "icon": "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/restaurant-71.png",
                    "distance": '??'
                }
            ]
            this.setState({
                data: data,
                loading: false
            })
            console.log("Just for you David, your favourite cafe")
        })
    }

    render() {
        const position = [this.state.lat, this.state.lng]
        const zoom = this.state.zoom
        return (
            <div>
                <h2 className="font-bold">Position: {this.state.lat.toFixed(5)}, {this.state.lng.toFixed(5)}</h2>
                {
                    this.state.loading ?
                    <Loader />
                    :
                    <div>
                        <Controls parent={this} />
                        <div className="container mx-auto">
                            <MapContainer center={position} zoom={zoom} scrollWheelZoom={true}>
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <SetView position={position} parent={this} />
                                <Marker position={position} icon={myPosIcon}>
                                    <Popup>
                                        <p>My Position</p>
                                    </Popup>
                                </Marker>
                                {
                                    this.state.data.map((item, index) => 
                                        <RestaurantMarker key={"rm_" + index} item={item} index={index} />
                                    )
                                }
                            </MapContainer>
                        </div>
                    </div>
                }   
            </div>
        )
    }
}

export default PlacesOfInterest