import L from "leaflet";


export const icon = L.divIcon({
    className: "restaurant-icon",
    iconSize: [20, 20],
    iconAnchor: [0, 0],
    popupAnchor: [15, 0],
});

export const myPosIcon = L.divIcon({
    className: "mypos-icon",
    iconSize: [20, 20],
    iconAnchor: [0, 0],
    popupAnchor: [15, 0],
});