import L from 'leaflet'
import { useMap } from 'react-leaflet'

function SetView(props) {
    const map = useMap()
    let items = props.parent.state.data
    if (items.length > 0) {
        const latLngs = []
        latLngs.push(props.position)
        items.map((item) =>
            latLngs.push([item.latitude, item.longitude])
        )
        const bounds = L.latLngBounds(latLngs)
        map.fitBounds(bounds)
    }
    map.setView(props.position)
    map.on('click', (e) => {
        props.parent.setState({
            lat: e.latlng.lat,
            lng: e.latlng.lng,
            zoom: 15
        })
    })

    return null
}

export default SetView