function Controls(props) {
    return <div>
        <div className="container flex flex-col mx-auto p-4 w-auto">
            <div className="grid grid-cols-4 text-center gap-2 lg:gap-8 my-2">
                <div>
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={props.parent.handleButtonClick}>Find Food</button>
                </div>
                <div>
                    <label htmlFor="radius">Radius: </label>
                    <select className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" id="radius" onChange={props.parent.setRadius}>
                        <option value="500">500m</option>
                        <option value="1000">1000m</option>
                        <option value="2500">2500m</option>
                        <option value="5000">5000m</option>
                        <option value="10000">10000m</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="food_type">Food type: </label>
                    <select className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" id="food_type" onChange={props.parent.setFoodType}>
                        <option value="pizza">Pizza</option>
                        <option value="curry">Curry</option>
                        <option value="thai">Thai</option>
                        <option value="cafe">Cafe</option>
                        <option value="kebab">Kebab</option>
                        <option value="chippy">Chippy</option>
                        <option value="italian">Italian</option>
                    </select>
                </div>
                <div>
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={props.parent.getGeoLocation}>Find Me</button>
                </div>
            </div>
        </div>
    </div>
}

export default Controls