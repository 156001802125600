import './App.css';
import PlacesOfInterest from './components/PlacesOfInterest'

function App() {
	return (
		<div className="App">
			<h1 className="bg-blue-500 p-5 font-extrabold text-lg mb-2">Places To Eat</h1>
			<PlacesOfInterest />
		</div>
	);
}

export default App;
